var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "company": _vm.entity.company,
      "title": _vm.entity.legalName,
      "sub-title": _vm.entity.companyNumber,
      "category": "Entity",
      "category-logo": "fa-sitemap"
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "entity-view",
      "tabs": _vm.tabs,
      "loading": _vm.loadingAction.get
    }
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }