<template>
  <div>
    <PageHeaderDisplay
      :company="entity.company"
      :title="entity.legalName"
      :sub-title="entity.companyNumber"
      category="Entity"
      category-logo="fa-sitemap"
    />
    <TabView route-name="entity-view" :tabs="tabs" :loading="loadingAction.get">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'EntityView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  data() {
    return {
      logoUrl: '',
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      entity: 'entity/entity',
      loadingAction: 'entity/loadingAction'
    }),
    currentTab() {
      return this.$route.params.tab || 'overview';
    },
    tabs() {
      const tabs = [
        {
          name: 'structure',
          route: 'entity-structure',
          icon: 'fa-sitemap'
        }
      ];

      if (this.$permissions.write('entity', this.entity)) {
        tabs.push({
          name: 'manage',
          route: 'entity-edit',
          icon: 'fa-cog'
        });
      }

      return tabs;
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      getEntity: 'entity/get'
    }),
    async refresh() {
      await this.getEntity({ id: this.$route.params.id });

      if (this.entity.logoUrl) return (this.logoUrl = this.entity.logoUrl);

      if (this.entity.websiteUrl) {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/util/company-logo-search?query=${this.entity.websiteUrl.replace(/http[s]*:\/\//g, '')}`
        );

        if (response.status === 200 && response.data && response.data[0]?.logo) this.logoUrl = response.data[0].logo;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.getEntity({ id: to.params.id });
    next();
  }
};
</script>
